<template>
  <div>
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <div class="row breadcrumbs-top d-flex">
          <h2 class="content-header-title float-start mb-0">
            Villa type Master
          </h2>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-2">
        <div class="col-12">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb float-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Villa type Master</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div
      class="content-header row"
      v-show="showGallery"
      @click="[(showTable = true), (showGallery = false)]"
    >
      <div class="content-header-left col-md-6 col-12 mb-2">
        <div class="row breadcrumbs-top d-flex"></div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-2">
        <div class="col-12">
          <div class="breadcrumb-wrapper float-end">
            <button type="button" class="btn btn-primary btn-cart move-cart">
              <vue-feather type="arrow-left"></vue-feather>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body" v-show="showTable">
      <section id="dashboard-ecommerce">
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card card-congratulation-medal">
              <div class="card-body">
                <div class="row mb-1">
                  <div class="d-flex col-12">
                    <div class="float-start col-6">
                      <div class="input-group input-group-merge w-50">
                        <span class="input-group-text" id="basic-addon-search2"
                          ><vue-feather type="search"></vue-feather
                        ></span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                          aria-label="Search..."
                          aria-describedby="basic-addon-search2"
                          v-model="search"
                          @keyup="searchData"
                        />
                      </div>
                    </div>
                    <!-- data-bs-toggle="modal"
                    data-bs-target="#modals-slide-in" -->
                    <div class="float-end col-6">
                      <button
                        type="button"
                        class="btn btn-gradient-primary float-end"
                        @click="add"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <section id="ajax-datatable">
                  <div class="row">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-datatable">
                          <table
                            class="dt-column-search table table-responsive"
                          >
                            <thead>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(li, index) in list"
                                :key="li.id"
                                v-show="list.length > 0"
                              >
                                <td>{{ index + paginationData.from }}</td>
                                <td>{{ li.villa_name }}</td>
                                <td>{{ li.allow_adult }}</td>
                                <td>{{ li.max_adult_limit }}</td>
                                <td>{{ li.price }}</td>
                                <td>{{ li.weekend_price }}</td>
                                <td>{{ li.desc }}</td>
                                <td>{{ li.created_at }}</td>
                                <td>{{ li.updated_at }}</td>
                                <td class="d-flex">
                                  <div
                                    class="d-flex justify-content-start align-items-center mb-75"
                                  >
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-primary rounded"
                                        @click="edit(li.id)"
                                      >
                                        <div class="avatar-content">
                                          <vue-feather
                                            type="edit"
                                            size="15"
                                          ></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                  <div
                                    class="d-flex justify-content-start align-items-center mb-75"
                                  >
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-success rounded"
                                        @click="view(li.id)"
                                      >
                                        <div class="avatar-content">
                                          <vue-feather
                                            type="eye"
                                            size="15"
                                          ></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                  <div
                                    class="d-flex justify-content-start align-items-center mb-75"
                                  >
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-danger rounded"
                                        @click="deleteVilla(li.id)"
                                      >
                                        <div class="avatar-content">
                                          <vue-feather
                                            type="trash-2"
                                            size="15"
                                          ></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr v-show="list.length === 0">
                                <td colspan="8" class="text-center">
                                  No Data Found
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </tfoot>
                          </table>
                          <div class="row d-flex">
                            <div class="col-12 d-flex">
                              <div class="col-6 showing_entry">
                                Showing {{ paginationData.from }} to
                                {{ paginationData.to }} of
                                {{ paginationData.total }} entries
                              </div>
                              <div class="col-6">
                                <Pagination
                                  :data="paginationData"
                                  @pagination-change-page="getResults"
                                  show-disabled="true"
                                  limit="3"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="content-body" v-show="showGallery">
      <section id="wishlist" class="grid-view wishlist-items">
        <div
          v-for="gellary in gallaryData"
          :key="gellary.id"
          class="card ecommerce-card"
        >
          <div class="item-img text-center wish_list">
            <a href="">
              <img :src="gellary.url" class="img-fluid" alt="img-placeholder" />
            </a>
          </div>
          <div class="item-options text-center">
            <button
              type="button"
              class="btn btn-primary btn-cart move-cart"
              @click="deleteImage(gellary.img, gellary.id)"
            >
              <i data-feather="shopping-cart"></i>
              <span class="add-to-cart">Remove</span>
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
  <teleport to="#modal">
    <div
      class="modal modal-slide-in new-user-modal fade"
      id="modals-slide-in"
      :class="isShow"
      :style="{ display: isDisplay }"
    >
      <div class="modal-dialog">
        <form
          class="add-new-user modal-content pt-0"
          @submit.prevent="submitForm"
          @keydown="form.onKeydown($event)"
          enctype="multipart/form-data"
        >
          <input type="hidden" name="id" :v-modal="form.id" />
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="[(isShow = ''), (isDisplay = 'none')]"
          >
            ×
          </button>
          <div class="modal-header mb-1">
            <h4 class="modal-title" id="exampleModalLabel">
              {{ modalName }} Villa Type
            </h4>
          </div>
          <div class="modal-body flex-grow-1">
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name"
                >Villa Type Name</label
              >
              <input
                type="text"
                class="form-control"
                name="villa_name"
                v-model="form.villa_name"
                placeholder="Villa Type Name"
                @keyup="
                  form.villa_name.length > 0
                    ? [(villaNameError = 'none'), (this.disabled = false)]
                    : (villaNameError = 'block')
                "
              />
              <div
                class="invalid-feedback fw-bold fst-italic"
                :style="{ display: villaNameError }"
              >
                Please enter Villa type name.
              </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name"
                >Description</label
              >
              <textarea
                class="form-control"
                name="desc"
                v-model="form.desc"
                placeholder="Description"
                @keyup="
                  form.desc.length > 0
                    ? [(descError = 'none'), (this.disabled = false)]
                    : (descError = 'block')
                "
              ></textarea>
              <div
                class="invalid-feedback fw-bold fst-italic"
                :style="{ display: descError }"
              >
                Please enter Description.
              </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name"
                >How Many Adult Allowed ?</label
              >
              <input
                type="number"
                class="form-control"
                name="allow_adult"
                v-model="form.allow_adult"
                id="allow_adult"
                placeholder="Allow Adult"
                @keyup="
                  form.allow_adult.toString().length > 0
                    ? [(allowAdultError = 'none'), (this.disabled = false)]
                    : (allowAdultError = 'block')
                "
              />
              <div
                class="invalid-feedback fw-bold fst-italic"
                :style="{ display: allowAdultError }"
              >
                Please enter Allowed Adult.
              </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name"
                >Maximum Adult Limit</label
              >
              <input
                type="number"
                class="form-control"
                name="max_adult_limit"
                v-model="form.max_adult_limit"
                id="max_adult_limit"
                placeholder="Maximum Adult Limit"
                @keyup="
                  form.max_adult_limit.toString().length > 0
                    ? [(maxAdultLimitError = 'none'), (this.disabled = false)]
                    : (maxAdultLimitError = 'block')
                "
              />
              <div
                class="invalid-feedback fw-bold fst-italic"
                :style="{ display: maxAdultLimitError }"
              >
                Please enter Max Adult Limit.
              </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name">Price</label>
              <input
                type="number"
                class="form-control"
                name="price"
                v-model="form.price"
                id="price"
                step="any"
                placeholder="Price"
                @keyup="
                  form.price.toString().length > 0
                    ? [(priceError = 'none'), (disabled = false)]
                    : (priceError = 'block')
                "
              />
              <div
                class="invalid-feedback fw-bold fst-italic"
                :style="{ display: priceError }"
              >
                Please enter price.
              </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name"
                >Weekend Price</label
              >
              <input
                type="number"
                class="form-control"
                name="weekend_price"
                v-model="form.weekend_price"
                id="weekend_price"
                step="any"
                placeholder="Weekend Price"
              />
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name">Images</label>
              <vue-dropzone
                ref="myVueDropzone"
                id="dropzone"
                :options="dropzoneOptions"
                @vdropzone-file-added="fileAdded"
                @vdropzone-removed-file="fileRemove"
              ></vue-dropzone>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name">Preview:</label>
              <div class="row">
                <div
                  @mouseover="selectIndex(index)"
                  @mouseout="ItemIndex = null"
                  class="villa_image col-md-2 col-6 profile-latest-img"
                  v-for="(hData, index) in htmlData"
                  :key="index"
                >
                  <img
                    :src="hData.url"
                    alt="villa_image"
                    class="img-fluid rounded"
                    style="height: 100%; width: 100%"
                    :class="ItemIndex == index ? `hoverClass` : ``"
                  />
                  <div
                    id="hoverEffect"
                    @click="deleteImage(hData.img, hData.id)"
                  >
                    <vue-feather
                      type="x"
                      v-show="ItemIndex == index"
                      size="50"
                    ></vue-feather>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-primary me-1 data-submit"
              :disabled="disabled"
            >
              Submit
            </button>
            <button
              type="reset"
              class="btn btn-outline-secondary"
              :disabled="disabled"
              data-bs-dismiss="modal"
              @click="[(isShow = ''), (isDisplay = 'none')]"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </teleport>
</template>

<script>
import axios from "axios";
import LaravelVuePagination from "laravel-vue-pagination";
import Form from "vform";
import vueDropzone from "vue2-dropzone-vue3";

export default {
  name: "VillaType",
  components: {
    Pagination: LaravelVuePagination,
    vueDropzone,
  },
  data() {
    return {
      form: new Form({
        id: null,
        villa_name: "",
        images: [],
        desc: "",
        allow_adult: "",
        max_adult_limit: "",
        price: "",
        weekend_price: "",
        created_at: "",
        updated_at: "",
      }),
      response: "",
      disabled: false,
      header: [],
      list: [],
      modalName: "Add",
      paginationData: {},
      search: null,
      villaNameError: "none",
      imagesError: "none",
      descError: "none",
      allowAdultError: "none",
      maxAdultLimitError: "none",
      isShow: "",
      isDisplay: "none",
      token: localStorage.getItem("auth_token"),
      tempAttachments: [],
      attachments: [],
      htmlData: [],
      ItemIndex: null,
      showGallery: false,
      showTable: true,
      gallaryData: [],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        acceptedFiles: ".jpeg,.jpg,.png,.gif",
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        maxFilesize: 0.5,
        addRemoveLinks: true,
        headers: { "My-Awesome-Header": "header value" },
      },
    };
  },
  async mounted() {
    this.header = [
      "Sr. no.",
      "Villa Name",
      "Allowed Adult",
      "Adult Limit",
      "Price",
      "Weekend Price",
      "Description",
      "Created At",
      "Updated At",
      "Action",
    ];
    this.getResults();
  },
  methods: {
    fileAdded(file) {
      this.form.images.push(file);
      let attachment = {};
      attachment._id = file.upload.uuid;
      attachment.title = file.name;
      attachment.type = "file";
      attachment.extension = "." + file.type.split("/")[1];
      attachment.user = JSON.parse(localStorage.getItem("user"));
      attachment.content = "File Upload by Select or Drop";
      attachment.thumb = file.dataURL;
      attachment.thumb_list = file.dataURL;
      attachment.isLoading = true;
      attachment.progress = true;
      attachment.size = file.size;
      this.tempAttachments = [...this.tempAttachments, attachment];
    },
    fileRemove(file) {
      this.form.images.splice(this.form.images.indexOf(file), 1);
    },
    selectIndex(Index) {
      this.ItemIndex = Index;
    },
    deleteImage(name, id) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/villa_type_master/delete_image/" + id,
          {
            name: name,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            this.$toast.success(response.data.message);
            const img = response.data.data?.new_images;
            this.gallaryData = [];
            this.htmlData = [];
            if (img) {
              img.map((value) => {
                if (value) {
                  this.htmlData.push({
                    id: this.form.id,
                    img: value,
                    url: response.data.data?.villa_url + value,
                  });
                  this.gallaryData.push({
                    id: response.data.data?.id,
                    img: value,
                    url: response.data.data?.villa_url + value,
                  });
                }
              });
            }
          } else {
            this.$toast.error(response.data.error?.[0]);
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
    async add() {
      (this.form.id = null),
        (this.form.villa_name = ""),
        (this.form.images = []),
        (this.form.desc = ""),
        (this.form.allow_adult = ""),
        (this.form.max_adult_limit = ""),
        (this.form.price = ""),
        (this.form.weekend_price = ""),
        (this.response = "");
      this.isShow = "show";
      this.isDisplay = "block";
      this.htmlData = [];
    },
    async getResults(page = 1) {
      await axios
        .get(process.env.VUE_APP_API_URL + "/villa_type_master?page=" + page, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          this.paginationData = response.data.data;
        });
      this.list = this.paginationData.data;
    },
    uploadImage(e) {
      let img = e.target.files[0];
      this.form.profile = img;
      this.url = URL.createObjectURL(img);
    },
    async submitForm() {
      this.disabled = true;
      if (this.form.villa_name.length === 0) {
        this.villaNameError = "block";
      } else if (this.form.desc.length === 0) {
        this.descError = "block";
      } else if (this.form.allow_adult.toString().length === 0) {
        this.allowAdultError = "block";
      } else if (this.form.max_adult_limit.toString().length === 0) {
        this.maxAdultLimitError = "block";
      } else if (this.form.price.toString().length === 0) {
        this.priceError = "block";
      } else {
        var apiUrl = "";
        if (this.form.id) {
          apiUrl =
            process.env.VUE_APP_API_URL +
            "/villa_type_master/update/" +
            this.form.id;
        } else {
          apiUrl = process.env.VUE_APP_API_URL + "/villa_type_master";
        }
        await this.form
          .post(apiUrl, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          })
          .then((response) => {
            if (response.data.status) {
              this.$toast.success(response.data.message);
              this.isShow = "";
              this.isDisplay = "none";
              this.disabled = false;
              this.getResults();
              location.reload();
            } else {
              this.$toast.error(response.data.error?.[0]);
              this.disabled = false;
            }
          })
          .catch((error) => {
            this.$toast.error(error);
            this.disabled = false;
          });
      }
    },
    deleteVilla(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ms-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .delete(process.env.VUE_APP_API_URL + "/villa_type_master/" + id, {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this.token}`,
              },
            })
            .then((response) => {
              this.getResults();
              if (response.data.status) {
                this.$toast.success(response.data.message);
              } else {
                this.$toast.error(response.data.error?.[0]);
              }
            })
            .catch((error) => {
              this.$toast.error(error);
            });
        }
      });
    },
    async edit(id) {
      this.villaNameError = "none";
      this.imagesError = "none";
      this.descError = "none";
      this.disabled = false;

      const output = await axios.get(
        process.env.VUE_APP_API_URL +
          "/villa_type_master" +
          (id ? "/" + id : ""),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        }
      );

      this.form.id = output.data.data?.id;
      this.form.villa_name = output.data.data?.villa_name;
      this.form.desc = output.data.data?.desc;
      this.form.allow_adult = output.data.data?.allow_adult;
      this.form.max_adult_limit = output.data.data?.max_adult_limit;
      this.form.price = output.data.data?.price;
      this.form.weekend_price = output.data.data?.weekend_price;
      this.htmlData = [];
      // const img = output.data.data?.images
      // const img = output.data.data?.villa_images;
      const img = output.data.data?.new_images;
      if (img) {
        img.map((value) => {
          if (value) {
            this.htmlData.push({
              id: this.form.id,
              img: value,
              url: output.data.data?.villa_url + value,
            });
          }
        });
      }
      this.modalName = "Edit";
      this.isShow = "show";
      this.isDisplay = "block";
    },
    async searchData() {
      await axios
        .post(
          process.env.VUE_APP_API_URL + "/villa_type_master/search",
          {
            search: this.search,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            this.list = response.data.data.data;
            this.isShow = "";
            this.isDisplay = "none";
          } else {
            this.$toast.error(response.data.error?.[0]);
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
    async view(id) {
      this.showGallery = true;
      this.showTable = false;
      this.gallaryData = [];
      const output = await axios.get(
        process.env.VUE_APP_API_URL +
          "/villa_type_master" +
          (id ? "/" + id : ""),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      // const img = output.data.data?.images
      // const img = output.data.data?.villa_images
      const img = output.data.data?.new_images;
      img.map((value) => {
        if (value) {
          this.gallaryData.push({
            id: output.data.data?.id,
            img: value,
            url: output.data.data?.villa_url + value,
            // 'url': value
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.villa_image {
  position: relative;
  opacity: 1;
  min-height: 83px;
  min-width: 83px;
}
.villa_image .img-fluid {
  z-index: -1;
}
.hoverClass {
  background-color: #7367f0;
  opacity: 0.5;
}
#hoverEffect {
  cursor: pointer;
}
#hoverEffect i {
  position: absolute;
  top: 25%;
  left: 28%;
  z-index: 999;
  color: white;
  font-weight: bolder;
}
.item-img {
  padding-bottom: 0.5rem;
}
.wish_list a .img-fluid {
  min-height: 25rem;
  min-width: 25rem;
  max-width: 25rem;
  max-height: 25rem;
  display: flex;
  text-align: center;
}
</style>
